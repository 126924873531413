import { GetOrdersStatusCode } from '@/enums/StatusCodes/StatusCode';
import { IItemSpec, IItemViewModel, IMerchantPortalOptionSettingViewModel, IMerchantPortalStyleSetting, INavBarOptionDto, IOperationResult, IOperationResultT, IPaginationResponse, IPortalQueryItemByMerchantRequest, IQueryOptionViewModel, IRegistRequest } from '@/Templates/interfaces/templatesInterfaces';
import {
    IAddItemToShoppingCartRequest,
    IAddItemToTempShoppingCartRequest,
    IAddMemberLogisticOptionRequest,
    ICheckOutRequest,
    ICheckOutWithOutRegistRequest,
    ICreatePaymentOrderRequest,
    IDeleteMemberLogisticOptionRequest,
    IFillMemberLogisticOptionWithThirdPartyLogisticRequest,
    IFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistRequest,
    IGetNavBarOptionsRequest,
    IGetPaymentOrderRequest,
    ILoginRequest,
    IRemoveItemsFromTempShoppingCartRequest,
    IRemoveItemsToShoppingCartRequest,
    IValidateStockAmountRequest
} from '../../interfaces/Requests';
import {
    IMemberLogisticOption,
    INavBarSetting,
    IOperationResultGenericWithError,
    IOrder,
    IOrderHistoryDetailViewModel,
    IPortalMerchantThirdPartyLogisticSettingViewModel,
    IPortalMerchantThirdPartyPaymentSettingViewModel,
    IShoppingCartViewModel,
    ISupportMemberFillAddressResponse,
    ITempShoppingCartViewModel,
    IThirdPartyPaymentCreateRespnse,
    IThirdPartyPaymentOrderViewModel,
    ITranslation,
    ValidateStockAmountResponse
} from '../../interfaces/Responses';
import useAPI from '../customHooks/useAPI';

export const useGetMerchantIsExpiredApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantIsExpired?merchantId=${merchantId}`, {
    signal
});

export const useMemberLoginApi = (params: ILoginRequest, signal?: AbortSignal) => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/Member/MemberLogin`, {
    method: 'POST',
    body: params,
    signal
});

export const useMemberRegistApi = (params: IRegistRequest) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Member/MemberRegist`, {
    method: 'POST',
    body: params

});

export const useGetMerchantPortalOptionSettingApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMerchantPortalOptionSettingViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantPortalOptionSetting?merchantId=${merchantId}`);

export const useGetMerchantPortalStyleSettingsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMerchantPortalStyleSetting[]>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantPortalStyleSettings?merchantId=${merchantId}`, {
    signal: signal,
});

export const useGetItemsByMerchantApi = (queryRequest: IPortalQueryItemByMerchantRequest, signal?: AbortSignal) => {
    const url = `${process.env.BASE_API_URL}/api/Portal/GetItemsByMerchant`;
    return useAPI<IOperationResultT<IPaginationResponse<IItemViewModel[]>>>(url, {
        method: 'GET',
        body: queryRequest,
        signal
    });
};
export const useGetItemSpecsApi = (merchantId: BigInt, itemId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IItemSpec[]>>(`${process.env.BASE_API_URL}/api/Item/GetItemSpecs?merchantId=${merchantId}&itemId=${itemId.toString()}`, {
    method: 'GET',
    signal: signal
});

export const useGetNavBarSettingApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<INavBarSetting>>(`${process.env.BASE_API_URL}/api/Portal/GetNavBarSetting?merchantId=${merchantId}`, {
    signal
});

export const useGetNavBarOptionsApi = (params: IGetNavBarOptionsRequest, signal?: AbortSignal) => useAPI<IOperationResultT<INavBarOptionDto[]>>(`${process.env.BASE_API_URL}/api/Portal/GetNavBarOptions`, {
    method: 'POST',
    body: params,
    signal
});

export const useGetCategoriesApi = (merchantId: BigInt) => useAPI(`${process.env.BASE_API_URL}/api/Category/GetMerchantCategories?merchantId=${merchantId}`);

export const useAddToTempShoppingCartApi = (params: IAddItemToTempShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ShoppingCart/AddItemToTempShoppingCart`, {
    method: 'POST',
    body: params
});

export const useAddToShoppingCartApi = (params: IAddItemToShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ShoppingCart/AddItemToShoppingCart`, {
    method: 'POST',
    body: params
});

export const useAddOrderDirectlyApi = (params: IAddItemToShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ShoppingCart/AddOrderDirectly`, {
    method: 'POST',
    body: params
});

export const useGetNoRegistOrderApi = (orderId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IOrder[]>>>(`${process.env.BASE_API_URL}/api/Portal/GetNoRegistOrder?orderId=${orderId.toString()}`);

export const useGetMemberOrdersApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IOrder[]>>>(`${process.env.BASE_API_URL}/api/Portal/GetMemberOrders`);

export const useGetShoppingCartApi = (params: BigInt | undefined, signal?: AbortSignal) => useAPI<IOperationResultT<IShoppingCartViewModel>>(params === undefined ? `${process.env.BASE_API_URL}/api/Portal/GetShoppingCart` : `${process.env.BASE_API_URL}/api/Portal/GetShoppingCart?sessionId=${params}`, {
    signal
});

export const useGetTempShoppingCartApi = (merchantId: BigInt, params: BigInt | undefined, signal?: AbortSignal) => useAPI<IOperationResultT<ITempShoppingCartViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetTempShoppingCart?merchantId=${merchantId}&sessionId=${params}`, {
    signal
});

export const useRemoveItemsToShoppingCartApi = (params: IRemoveItemsToShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/RemoveItemsToShoppingCart`, {
    method: 'POST',
    body: params
});

export const useRemoveItemsFromTempShoppingCartApi = (params: IRemoveItemsFromTempShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/RemoveItemsFromTempShoppingCart`, {
    method: 'POST',
    body: params

});

export const useValidateStockAmountApi = (params: IValidateStockAmountRequest, signal?: AbortSignal) => useAPI<IOperationResultT<ValidateStockAmountResponse[]>>(`${process.env.BASE_API_URL}/api/Portal/ValidateStockAmount`, {
    method: 'POST',
    body: params,
    signal
});

export const useCheckOutOrderWithAllowNoRegistApi = (params: ICheckOutWithOutRegistRequest, signal?: AbortSignal) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Portal/CheckOutOrderWithAllowNoRegist`, {
    method: 'POST',
    body: params,
    signal
});

export const useGetOrderHistoryDetailViewModelApi = (orderId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IOrderHistoryDetailViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetOrderHistoryDetailViewModel?orderId=${orderId}`, {
    signal
});

export const useGetMemberLogisticOptionsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMemberLogisticOption[]>>(`${process.env.BASE_API_URL}/api/Portal/GetMemberLogisticOptions?merchantId=${merchantId}`, {
    signal
});

export const useGetAnonymousLogisticOptionsApi = (sessionId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMemberLogisticOption[]>>(`${process.env.BASE_API_URL}/api/Portal/GetAnonymousLogisticOptions?sessionId=${sessionId.toString()}`, {
    signal
});

export const useAddMemberLogisticOptionApi = (params: IAddMemberLogisticOptionRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/AddMemberLogisticOption`, {
    method: 'POST',
    body: params
});

export const useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPortalMerchantThirdPartyPaymentSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/Portal/GetPortalMerchantThirdPartyPaymentSettingViewModels?merchantId=${merchantId}`, {
    signal
});

export const useGetMerchantThirdPartyLogisticSettingViewModes = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPortalMerchantThirdPartyLogisticSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantThirdPartyLogisticSettingViewModels?merchantId=${merchantId}`, {
    signal
});

export const useFillMemberLogisticOptionWithThirdPartyLogisticApi = (params: IFillMemberLogisticOptionWithThirdPartyLogisticRequest) => useAPI<IOperationResultT<ISupportMemberFillAddressResponse>>(`${process.env.BASE_API_URL}/api/Portal/FillMemberLogisticOptionWithThirdPartyLogistic`, {
    method: 'POST',
    body: params

});

export const useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi = (params: IFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistRequest) => useAPI<IOperationResultT<ISupportMemberFillAddressResponse>>(`${process.env.BASE_API_URL}/api/Portal/FillMemberLogisticOptionWithThirdPartyLogisticWithNoRegist`, {
    method: 'POST',
    body: params
});

export const useGetLogisticsMainTypesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Portal/GetLogisticsMainTypes`, {
    signal
});

export const useGetPaymentMainTypesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Portal/GetPaymentMainTypes`, {
    signal
});

export const useDeleteMemberLogisticOptionApi = (params: IDeleteMemberLogisticOptionRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/DeleteMemberLogisticOption`, {
    method: 'DELETE',
    body: params
});

export const useGetSupportedLanguagesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Portal/GetSupportedLanguages`, {
    signal
});

export const useGetGetTranslationsApi = (language: string, signal?: AbortSignal) => useAPI<IOperationResultT<ITranslation[]>>(`${process.env.BASE_API_URL}/api/Portal/GetTranslations?language=${language}`, {
    signal
});

export const useGetLastestThirdPartyPaymentOrderApi = (params: IGetPaymentOrderRequest, signal?: AbortSignal) => useAPI<IOperationResultGenericWithError<IThirdPartyPaymentOrderViewModel, GetOrdersStatusCode>>(`${process.env.BASE_API_URL}/api/Portal/GetLastestThirdPartyPaymentOrder`, {
    method: 'POST',
    body: params,
    signal
});

export const useGetPortalDefaultLanguageApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/Portal/GetPortalDefaultLanguage?merchantId=${merchantId}`, {
    signal
});

export const useCheckOutApi = (params: ICheckOutRequest) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Portal/CheckOut`, {
    method: 'POST',
    body: params
});

export const useCreatePaymentOrder = (params: ICreatePaymentOrderRequest) => useAPI<IOperationResultT<IThirdPartyPaymentCreateRespnse>>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/CreateOrder`, {
    method: 'POST',
    body: params
});

export const useGetLogisticSupportPaymentSettingIdsApi = (logisticSettingId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<BigInt[]>>(`${process.env.BASE_API_URL}/api/Portal/GetLogisticSupportPaymentSettingIds?logisticSettingId=${logisticSettingId.toString()}`, {
    signal
});

export const useGetQueryOptionsByMerchantIdApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IQueryOptionViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetQueryOptionsByMerchantId?merchantId=${merchantId}`, {
    signal
});

export const useGetSupportedCountriesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Portal/GetSupportedCountries`, {
    signal
});